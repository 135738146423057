import './Prevencion.scss'

import Toolbar from "../components/Toolbar";
import { IonPage, IonContent, IonItem, IonNote, IonIcon,} from "@ionic/react"
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { selectReportPRLDict } from '../features/report/reportSlice';
import { alertCircleOutline, documentAttachOutline, documentsOutline, medkitOutline, pricetagsOutline, schoolOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import PreventionDocument from '../components/Prevention/PreventionDocument';
import PreventionTable from '../components/Prevention/PreventionTable';
import { requestReportPRLList } from '../actions/getReports';


const Prevencion:any = ()=>{

    const [t] = useTranslation("global");

    const [activeTab, setActiveTab] = useState(0);
    const handleTabClick = ( index : number ) => {
        setActiveTab(index);
    };

    const dispatch = useAppDispatch();

    useEffect(() => { dispatch(requestReportPRLList()) }, [])
    const reportPRLDict = useAppSelector(selectReportPRLDict);

    interface IPreventionColumns {
        title : string
        data  : any[]
        type  : string
    }

    interface IPreventionTabs {
        name    : string
        name_s  : string
        icon    : string
        columns : IPreventionColumns[]
    }

    const [preventionTabs, setPreventionTabs] = useState<IPreventionTabs[]>([]);

    useEffect(() => {
        setPreventionTabs([
            {
                name    : t('Planes de Emergencia'),
                name_s  : t('Plan de Emergencia'),
                icon    : alertCircleOutline,
                columns : [
                    {
                        title : '',
                        data  : reportPRLDict.emergency_plans,
                        type  : 'document'
                    }
                ]
            },
            {
                name    : t('Evaluaciones'),
                name_s  : t('Evaluacion'),
                icon    : medkitOutline,
                columns : [
                    {
                        title : '',
                        data  : reportPRLDict.evaluations,
                        type  : 'document'
                    }
                ]
            },
            {
                name    : t('Fichas Informativas'),
                name_s  : t('Ficha Informativa'),
                icon    : documentsOutline,
                columns : [
                    {
                        title : '',
                        data  : reportPRLDict.info_sheet_workers,
                        type  : 'document'
                    }
                ]
            },
            {
                name    : t("EPI'S"),
                name_s  : t("EPI"),
                icon    : pricetagsOutline,
                columns : [
                    {
                        title : "EPI'S Pendientes",
                        data  : reportPRLDict.ipes_pending,
                        type  : 'table'
                    },
                    {
                        title : "EPI'S Recibidos",
                        data  : reportPRLDict.ipes_delivered,
                        type  : 'table'
                    },
                ]
            },
            {
                name    : t('Formaciones'),
                name_s  : t('Formacion'),
                icon    : schoolOutline,
                columns : [
                    {
                        title : 'Formaciones Pendientes',
                        data  : reportPRLDict.trainings_pending,
                        type  : 'table'
                    },
                    {
                        title : 'Formaciones Recibidas',
                        data  : reportPRLDict.trainings_delivered,
                        type  : 'table'
                    },
                ]
            }
        ])
        
    return () => {
    };
    }, [reportPRLDict]);

    return(
        <IonPage>
            <Toolbar title={t('Información de Prevención')} />
            <IonContent fullscreen>
                <div className="prevention">
                    <ul className="tabs">
                        {
                            preventionTabs.map(( tab, i ) => {
                                return (
                                    <li className={`tabs__li ${ i === activeTab ? 'active' : '' }`} key={i}>
                                        <button onClick={() => handleTabClick( i )} type="button" className="tabs__button">
                                            <IonIcon aria-hidden="true" ios={ tab.icon } md={ tab.icon } />
                                            { tab.name }
                                        </button>
                                    </li>
                                )
                            })
                        }
                    </ul>
                    {
                        preventionTabs.map(( tab, i ) => {
                            return (
                                <div className={`tabs__wrapper ${ i === activeTab ? 'active' : '' }`} key={i}>
                                    {
                                        tab.columns && tab.columns.map(( column, index ) => (
                                            <div className="tabs__body" key={index}>
                                                {
                                                    <div className="tabs__header"><div className="tabs__title">{ column.title ? column.title : tab.name } ({column.data && column.data.length}):</div></div>
                                                }
                                                {
                                                    column.type === 'document' 
                                                    ? column.data && 
                                                    <ul className="data-list">
                                                        {   
                                                            column.data.length > 0 ? column.data.map(( d , indx ) => ( 
                                                                <PreventionDocument key={indx} data={d} title={column.title ? column.title : tab.name } title_s={ tab.name_s }/> 
                                                            ))
                                                            : <li className='report__file file file--no-hover'>
                                                                <p className="file__info">
                                                                    <span className="file__type">No hay { tab.name }</span>
                                                                </p>
                                                            </li>
                                                        }
                                                    </ul>
                                                    : <PreventionTable key={index} data={column.data} title={column.title} title_s={ tab.name_s }/>
                                                }
                                                
                                            </div>
                                        ))
                                    }
                                </div>   
                            )
                        })
                    }
                </div>
            </IonContent>
        </IonPage>
    )
}

export default Prevencion