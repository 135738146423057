import { IonIcon, IonItem } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { documentAttachOutline, documentsSharp } from 'ionicons/icons';
import { useAppDispatch } from '../../app/hooks';
import { requestReport } from '../../actions/getReports';
import SignStatus from './SignStatus';

interface IPreventionDocument {
    data: any;
    title: string;
    title_s: string;
}

function PreventionDocument( props : IPreventionDocument ) {
    const [t] = useTranslation("global");
    const { data, title, title_s } = props

    const dispatch = useAppDispatch();

    const handleRequestReport = ( e : any ) => {
        e.stopPropagation();
        dispatch(requestReport({report_type: data.file_type, report_id: data.id, report_name: data.name}))
    }

    const dateRegex = /\b\d{4}-\d{2}-\d{2}\b/;
    const timeRegex = /\b\d{2}:\d{2}:\d{2}\b/;

    const dateMatch = data.name.match(dateRegex);
    const timeMatch = data.name.match(timeRegex);

    let fileDate = '';

    fileDate += dateMatch ? ` ${ dateMatch[0].split('-')[2]}-${dateMatch[0].split('-')[1]}-${dateMatch[0].split('-')[0]}` : ''
    fileDate += timeMatch ? ' ' + timeMatch[0].split(':')[0] + ':' + timeMatch[0].split(':')[1] : ''

    // {id, name, is_report, file_type}

    return (
        data &&
        <li title={ t('Descargar') + ' ' + title_s } className="file" onClick={ handleRequestReport }>
            <span className="file__icon">
                <IonIcon aria-hidden="true" className='report__icon' ios={documentAttachOutline} md={documentAttachOutline}></IonIcon>
            </span>
            <p className="file__info">
                <span className="file__type">{ title_s } { fileDate && fileDate.length > 0 && <span className="file__date">{ fileDate }</span>}</span>
                <span className='file__name'>{ data.name }</span>
            </p>
            {
                data.sign_status &&
                <SignStatus signStatus={data.sign_status} reportType={data.file_type} reportId={data.id} />
            }
        </li>
    );
}

export default PreventionDocument;