import { useState } from 'react';
import { IonButtons, IonButton, IonHeader, IonContent, IonToolbar, IonTitle, IonPage, IonList, IonLabel, IonNote, IonInput, IonToast } from '@ionic/react';
import "./ChangePasswordActivate.css"
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { activateAccount } from '../actions/loginInfo';
import { selectPasswordValidationMessages, selectWrongNewPassword, unsetWrongPasswordValidation } from '../features/login/loginSlice';
import { useTranslation } from 'react-i18next';


const ChangePasswordActivate = ({
    uuid,
    token,
    serverUrl
    }: {
        uuid: string;
        token: string;
        serverUrl: string;
    }) => {        
      const [t] = useTranslation("global");
    
      const [oldPassword, setOldPassword] = useState("")
      const [newPassword, setNewPassword] = useState("")
      const [newPasswordConfirm, setNewPasswordConfirm] = useState("")
      const [notPasswordCoincidence, setNotPasswordCoincidence] = useState(false)

      const dispatch = useAppDispatch();
      const wrongPasswordValidation = useAppSelector(selectWrongNewPassword)
      const passwordValidationMessages = useAppSelector(selectPasswordValidationMessages)

      const handleOldPassword = (e:any) => {
        setOldPassword(e.detail.value)
      }

      const handleNewPassword = (e:any) => {
        setNewPassword(e.detail.value)
      }

      const handleNewPasswordConfirm = (e:any) => {
        setNewPasswordConfirm(e.detail.value)
      }

      const handleChangePassword = (e:any) => {
        e.preventDefault()
        dispatch(unsetWrongPasswordValidation())
        if (newPassword !== newPasswordConfirm){
          setNotPasswordCoincidence(true)
        } else {
          dispatch(activateAccount({uuid, token, oldPassword, newPassword, serverUrl}))
        }


      }

    return (
      <IonPage>
        <form className='change-psw' onSubmit={(e) => handleChangePassword(e)}>
          <ul className="change-psw__list">
            <li className="change-psw__item">
              <IonLabel className='change-psw__label'>{t('Contraseña antigua')}:</IonLabel>
              <IonInput required type="password" onIonChange={(e) => handleOldPassword(e)} placeholder="********"></IonInput>
            </li>
            <li className="change-psw__item">
              <IonLabel className='change-psw__label'>{t('Contraseña nueva')}:</IonLabel>
              <IonInput required type="password" onIonChange={(e) => handleNewPassword(e)} placeholder="********"></IonInput>
            </li>
            <li className="change-psw__item">
              <IonLabel className='change-psw__label'>
                {t('Contraseña nueva')}:
                <span>({t('Confirmación')})</span>
              </IonLabel>
              <IonInput required type="password" onIonChange={(e) => handleNewPasswordConfirm(e)} placeholder="********"></IonInput>
            </li>
          </ul>
          <IonButtons>
            <IonButton className='change-psw__button' type='submit'>{t('Confirmar')}</IonButton>
          </IonButtons> 
        </form>
        <IonHeader>
          <IonToolbar>
            <IonTitle>{t('Cambiar contraseña para activar la cuenta')}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding request">

          
        </IonContent>
        <IonList>
          {
            wrongPasswordValidation ? passwordValidationMessages?.map((validationMessage) => (
                <IonNote>{validationMessage}</IonNote>
            )) 
            : null
          }
          
        </IonList>
        <IonToast
            isOpen={notPasswordCoincidence}
            onDidDismiss={() => setNotPasswordCoincidence(false)}
            message={t('Las contraseñas no coinciden')}
            duration={2000}
        />
      </IonPage>
    );
  };


  export default ChangePasswordActivate