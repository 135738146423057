import './Report.scss'

import { useTranslation } from 'react-i18next';

interface Reports {
    id: number,
    title: String,
    Idate: String,
    Edate: String,
    result: Boolean,
}

const Aptitude : any = (props : any)=>{
    
    const [t] = useTranslation("global");
    
    const {id, aptitude, aptitude_type, start_date, end_date, workstation} = props.rep
    
    return(
        <div key={id} className="report">
            <div className="report__header">
                <div className="report__title">
                    {workstation}
                    <span className="report__date">
                    {
                        `${ start_date?.split('T')[0].split('-')[2] }/${ start_date?.split('T')[0].split('-')[1] }/${ start_date?.split('T')[0].split('-')[0] }
                         ${end_date ? ` - ${ end_date?.split('T')[0].split('-')[2] }/${ end_date?.split('T')[0].split('-')[1] }/${ end_date?.split('T')[0].split('-')[0] }`
                         : ''}
                        `
                    }
                    </span>
                </div>
            </div>
            <div className="report__body">
                <div className="report__props">
                    {/* <div className="report__prop">
                        <div className="report__prop--title">
                            Inicio:
                        </div>
                    {
                        `${ start_date?.split('T')[0].split('-')[2] }-${ start_date?.split('T')[0].split('-')[1] }-${ start_date?.split('T')[0].split('-')[0] }`
                    }
                    </div>
                    <div className="report__prop">
                        <div className="report__prop--title">
                            Finalización: 
                        </div>
                        {end_date?.split('T')[0]}
                    </div> */}
                    <div className="report__prop"> 
                        <div className="report__prop--title">
                            {t('Resultado')}:
                        </div>
                        <div className="report__result">
                            {aptitude}
                        </div>
                    </div>
                    
                </div>
            </div>

        </div>
    )
}



export default Aptitude