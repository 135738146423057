import axios from "axios"
import { tokenConfig } from '../helpers/setAuthToken';
import { initialNotifications, updateNotifications } from "../features/notification/notificationSlice";
import { setExpiredSession } from "../helpers/expiredSession"

import { INotification } from "../features/notification/interfaces"

export const addNotification = ( data : INotification ) => async (dispatch:any) => {
    const serverUrl = localStorage.getItem("server_url")

    try {
        // Form Data

        const form_data = new FormData();
        form_data.append('type', data.type);
        form_data.append('department', data.department.id);
        form_data.append('subject', data.subject);
        form_data.append('message', data.message);
        form_data.append('sent_datetime', data.sent_datetime);
        // Añadir el resto de campos
    
        const response = await axios.post<any>( 
            `${serverUrl}/api/post_portal_notification/`,
            form_data,
            tokenConfig(),
        );
        
        const newNotification = {...data}
        newNotification.department = data.department.name

        dispatch(updateNotifications(newNotification))

    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.response);
            if (error.response?.statusText == "Unauthorized") {
                setExpiredSession()
            }
            return error.message;
        } else {
            console.log('unexpected error: ', error);
            return 'An unexpected error occurred';
        }
    }


    
}


export const getNotifications = () => async (dispatch:any) => {
    const serverUrl = localStorage.getItem("server_url")

    try {
        const { data } = await axios.get<any>( 
            `${serverUrl}/api/get_portal_notification/`,
            tokenConfig()
        );

        dispatch(initialNotifications(data))

    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            if (error.response?.data.code == "token_not_valid") {
                setExpiredSession()
            }
            return error.message;
        } else {
            console.log('unexpected error: ', error);
            return 'An unexpected error occurred';
        }
    }
}