import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { INotifications } from './interfaces';

const initialState: INotifications = {
    departments   : [],
    notifications : []
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    initialNotifications: (state, action) => {
      state.notifications = action.payload.notifications
      state.departments   = action.payload.departments
    },
    updateNotifications: (state, action) => {
      state.notifications = [...state.notifications, action.payload]
    }
  },
});

export const { initialNotifications, updateNotifications } = notificationSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectNotifications = (state: RootState) => (state.notifications.notifications);
export const selectDepartments   = (state: RootState) => (state.notifications.departments);

export default notificationSlice.reducer;
