import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from './store';

import { useState } from 'react';

import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera';

import { FilePicker } from '@capawesome/capacitor-file-picker';
import { INotificationFile } from '../features/notification/interfaces';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export function usePhotoGallery() {
    const [ photos, setPhotos] = useState<INotificationFile[]>([]);

    const takePhoto = async () => {
        const photo = await Camera.getPhoto({
            resultType: CameraResultType.Uri,
            source: CameraSource.Camera,
            quality: 100,
        });

        const fileName = Date.now() + '.jpeg';
        const base64Data = await base64FromPath(photo.webPath!);

        const newPhotos = [
        {
            name: fileName,
            data: base64Data
        },
        ...photos,
        ];

        console.log(newPhotos)

        setPhotos(newPhotos);
    };

    return {
        photos,
        setPhotos,
        takePhoto,
    };
}

export async function base64FromPath(path: string): Promise<string> {
    console.log(path)

    const response = await fetch(path);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = reject;
        reader.onload = () => {
        if (typeof reader.result === 'string') {
            resolve(reader.result);
        } else {
            reject('method did not return a string');
        }
        };
        reader.readAsDataURL(blob);
    });
}

export function useFilePicker() {
    const [ images, setImages ] = useState<any[]>([]);
    const [ documents, setDocuments ] = useState<any[]>([]);

    const newImages    : any = []
    const newDocuments : any = []
    
    const pickFile = async () => {
        const result = await FilePicker.pickFiles({ types : [] , multiple : true , readData : true });
        const newFiles = [ ...result.files ]

        newFiles.forEach( file => {
            const type = file.mimeType.split('/')[0]
            if( type ){
                console.log(file)
                if( type === 'image' ){
                    const isInArray = images.find( image => image.name === file.name )
                    if( !isInArray ) newImages.push({ name : file.name , data : `data:${ file.mimeType };base64,${ file.data }`})
                } else {
                    const isInArray = documents.find( document => document.name === file.name )
                    if( !isInArray ) newDocuments.push({ name : file.name , data : `data:${ file.mimeType };base64,${ file.data }`})
                }
            }
        })
        
        setImages   ( prevImages    => [...prevImages   , ...newImages    ])
        setDocuments( prevDocuments => [...prevDocuments, ...newDocuments ])
        // setFiles(prevFiles => [...prevFiles, ...newFiles]);
    };

    return {
        images,
        setImages,
        documents,
        setDocuments,
        pickFile,
    };
}