import "./ModalNotificationFiles.scss"

import { IonButton, IonButtons, IonHeader, IonIcon, IonPage, IonTitle, IonToolbar, IonImg} from '@ionic/react';
import { closeOutline, documentAttachOutline, imageOutline, trashOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/scss';
import 'swiper/scss/autoplay';
import 'swiper/scss/keyboard';
import 'swiper/scss/pagination';
import 'swiper/scss/scrollbar';
import 'swiper/scss/zoom';
import { useState } from "react";
import { INotificationFile } from "../../features/notification/interfaces";
// import '@ionic/react/css/ionic-swiper.css';

const ModalNotificationFiles = ({
    onDismiss, data
    }: {
        onDismiss: (data?: INotificationFile[], role?: string) => void;
        data : any
    }) => {       

    const [t] = useTranslation("global");
    const [ modalFiles , setModalFiles ] = useState<INotificationFile[]>([...data.files])
    const [ deletedFiles , setDeletedFiles ] = useState<INotificationFile[]>([])

    const canDelete = data.canDelete ? data.canDelete : false

    function handleDeleteFile( file : INotificationFile ){
      const newModalFiles = modalFiles.filter( modalFile => modalFile.name !== file.name && modalFile.data !== file.data )
      setModalFiles( newModalFiles )
      setDeletedFiles( prevDeletedFiles => [...prevDeletedFiles, file ])
    }

    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="end">
              <IonButton className='user-profile__close-button' title={t('Salir')} color="medium" onClick={() => onDismiss( deletedFiles, 'cancel')}>
                <IonIcon className='user-profile__close' ios={closeOutline} md={closeOutline}/>
              </IonButton>
            </IonButtons>
            <IonTitle>{ data.type === 'images' ? t('Imágenes Adjuntas') : t('Documentos Adjuntos') }</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className="ion-padding see-files">
            <Swiper spaceBetween={10}
                    slidesPerView={1}
                    centeredSlides
                    navigation
                    pagination={{ clickable: true }}
                    keyboard
                    zoom>
            { data.type === 'images' &&
              modalFiles && modalFiles.map(( file : INotificationFile ) => (
                    <SwiperSlide>
                      <div className="file slider-file no-hover"> 
                          <span className="file__icon">
                              <IonIcon aria-hidden="true" ios={imageOutline} md={imageOutline}></IonIcon>
                          </span>
                          <p className="file__info">
                              <span className="file__type">{ file.name }</span>
                              {
                                canDelete &&
                                <span className="file__delete" onClick={() => handleDeleteFile(file)} title={t('Eliminar Imagen')}><IonIcon aria-hidden="true" icon={trashOutline}></IonIcon></span>
                              }                              
                          </p>
                      </div>
                      <div className="img-container">
                        <IonImg src={ file.data }></IonImg>
                      </div>
                    </SwiperSlide>
              ))
            }
            </Swiper>
          {
              data.type === 'documents' &&
              modalFiles && modalFiles.map(( file : INotificationFile ) => (
              <div className="report__file file"> 
                  <span className="file__icon">
                      <IonIcon aria-hidden="true" ios={documentAttachOutline} md={documentAttachOutline}></IonIcon>
                  </span>
                  <p className="file__info">
                      <span className="file__type">{ file.name }</span>
                      {
                        canDelete &&
                        <span className="file__delete" onClick={() => handleDeleteFile(file)} title={t('Eliminar Documento')}><IonIcon aria-hidden="true" icon={trashOutline}></IonIcon></span>
                      }  
                  </p>
              </div>
            ))
          }
        </div>
      </IonPage>
    );
  };


  export default ModalNotificationFiles