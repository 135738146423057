import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import workerReducer from '../features/worker/workerSlice';
import reportReducer from '../features/report/reportSlice';
import loginReducer from '../features/login/loginSlice';
import appointmentReducer from '../features/appointment/appointmentSlice';
import historicalVariablesReducer from '../features/historicalVariables/historicalVariablesSlice';
import notificationReducer from '../features/notification/notificationSlice';

export const store = configureStore({
  reducer: {
    worker: workerReducer,
    report: reportReducer,
    login: loginReducer,
    appointment: appointmentReducer,
    notifications: notificationReducer,
    historicalVariables: historicalVariablesReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;