import { useTranslation } from 'react-i18next';
import SignStatus from './SignStatus';
import { IonItem } from '@ionic/react';

// TODO interface for props
function PreventionTable(props: any) {
    const [t] = useTranslation("global");
    const { data, title, } = props;

    return (
        <table className='dataTable'>
            <thead>
                <tr>
                    <th className='dataTable__id'>ID</th>
                    <th>NOMBRE</th>
                </tr>
            </thead>
            <tbody>
                {
                    data && data.length > 0 ? data.map((item: any, i: number) => (
                        <tr key={i}>
                            <td>{item.id}</td>
                            <td className={`prevention-table__name ${ item.sign_status ? 'prevention-table__name--with-status' : '' }`}>
                                <IonItem title={ t('Ver más') + '\n' + item.name } className='prevention-table__a' button lines='none' detail={false} routerLink={`/view/report/${item.file_type}/${item.id}`}>
                                    <div className="prevention-table__content">
                                        <p className='prevention-table__text'>{item.name}</p>
                                        {
                                            item.sign_status &&
                                            <div className="prevention-table__status">
                                                <SignStatus signStatus={item.sign_status} reportType={item.file_type} reportId={item.id} />
                                            </div>
                                        }
                                    </div>
                                </IonItem>
                            </td>
                        </tr>
                    )) :
                        <tr>
                            <td></td>
                            <td>{t('No hay')} {title}</td>
                        </tr>
                }
            </tbody>
        </table>
    );
}

export default PreventionTable;