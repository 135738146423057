export const StatusTranslation:any = {
    programmed: 'Pendiente',
    confirmed: 'Confirmada',
    refused: 'Rechazada'
}

export const StatusNotification:any = {
    programmed: 'pending',
    confirmed: 'accepted',
    refused: 'denied'
}