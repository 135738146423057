import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { FileOpener } from "@ionic-native/file-opener"
import { setReportDownloaded } from '../features/report/reportSlice';
import notifications from '../components/utils/Notifications';

const convertBlobToBase64 = (blob) => new Promise((resolve, reject) => {
    const reader = new FileReader;
    reader.onerror = reject;
    reader.onload = () => {
        resolve(reader.result);
    };
    reader.readAsDataURL(blob);
});

export const download = async (blobfile, reportName, dispatch) => {
    const base64String = await convertBlobToBase64(blobfile);
    localStorage.setItem("DUMMY", reportName)
    try {
        Filesystem.writeFile({
            path: reportName,
            data: base64String,
            directory: Directory.Documents,
        }).then((writeFileResult) => {
            dispatch(setReportDownloaded())
            notifications.schedule(reportName)
          });
      } catch(e) {
        console.error('Unable to write file', e);
      }
}