import { IonItem, IonLabel } from '@ionic/react';
import { useTranslation } from 'react-i18next';

interface SignStatusProps {
    signStatus: string;
    reportType: string;
    reportId: string;
}

function SignStatus(props: SignStatusProps) {
    const [t] = useTranslation("global");
    const { signStatus, reportType, reportId } = props

    const handleSignClick = (e:any) => e.stopPropagation();

    const signStatusVerbose : string[] = ["Pendiente firma", "Pendiente firma Enc./Resp.", "Pendiente mi firma", "Firmado"]

    return (
        <>
            <span className={`status_field status_field-${signStatus}`}>
                {signStatusVerbose[+signStatus]}
            </span>
            { signStatus === "0" || signStatus === "2" ? (
                <>
                    {/* smth like <SignDocument reportId={reportId} reportType="worker_ipe_delivery" /> */}
                    {/* you can only sign if its unsigned by either responsible (ipes_sign_pending_worker sign_status=2) or neither (ipes_sign_pending sign_status=0) */}
                    <IonItem
                        lines='none'
                        button
                        detail={false}
                        className="status_field--button"
                        routerLink={`/firmar/report/${reportType}/${reportId}`}
                        routerDirection="forward"
                        onClick={ handleSignClick }
                    >
                        <IonLabel>{t("Firmar")}</IonLabel>
                    </IonItem>
                </>
            ) : null }
        </>
    );
}


export default SignStatus