import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ServerUrlContext } from './ServerUrlContext';

export const ServerUrlProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [serverUrl, setServerUrl] = useState<string>(''); // Update the type to string

    // TODO: ¿Coger config.json solamente cuando no esté cargado en localStorage?

    useEffect(() => {
        const fetchServerUrl = async () => {
            const { data } = await axios.get('/config.json');

            console.log(`ServerUrlProvider: serverUrl: ${data.SERVER_URL}`);
            setServerUrl(data.SERVER_URL);
        };

        
        fetchServerUrl();
    }, []);

    return (
        <ServerUrlContext.Provider value={serverUrl}>
            {children}
        </ServerUrlContext.Provider>
    );
};