import { IonButtons, IonHeader, IonMenuButton, IonSelect, IonSelectOption, IonTitle, IonToolbar } from "@ionic/react"
import { useTranslation } from "react-i18next";

const Toolbar : any = (props : any)=>{

    const title = props.title;

    const [t, i18n] = useTranslation("global");

    const handleChangeLanguage = ( e : any ) => {
        if( props.language ){
            props.setLanguage(e.detail.value);
        }
        i18n.changeLanguage(e.detail.value);

        localStorage.setItem('language', e.detail.value);
    };
    
    return(
        <IonHeader>
            <IonToolbar>
                <IonButtons slot="start">
                    <IonMenuButton />
                </IonButtons>
                <IonTitle>{ title }</IonTitle>
                <IonButtons slot="end">
                <IonSelect value={i18n.language} onIonChange={handleChangeLanguage}>
                    <IonSelectOption value='es'  className="flag-option">{t('Español')}</IonSelectOption>
                    <IonSelectOption value='ca' className="flag-option">{t('Catalán')}</IonSelectOption>
                </IonSelect>
            </IonButtons>
            </IonToolbar>
        </IonHeader>
    )
}



export default Toolbar