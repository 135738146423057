import './Report.scss'

interface Reports {
    id: number,
    title: String,
    Idate: String,
    Edate: String,
    result: Boolean,
}

const Advice : any = (props : any)=>{
    
    const {id, report_type, date, free_advices, consultation_advices} = props.adv
    
    return(
        <div key={id} className="report">
            <div className={`report__header ${consultation_advices.length === 0 ? 'empty' : ''}`}>
                <div className="report__title">
                    {report_type}
                    <span className="report__date">
                        {`${ date?.split('T')[0].split('-')[2] }/${ date?.split('T')[0].split('-')[1] }/${ date?.split('T')[0].split('-')[0] }`}
                    </span>
                </div>
            </div>
            {
                consultation_advices.length > 0 ?
                    <div className="report__body">
                        <div className="report__props">
                            {consultation_advices.map((advice:any) => {
                                return <div key={advice.id} className="report__prop report__prop--advice">{advice.advice}</div>
                            })}
                            <div dangerouslySetInnerHTML={{__html:free_advices}} className="report__prop long"></div>
                        </div>
                    </div>
                 : ''
            }
        </div>
    )
}



export default Advice