import { IonPage, IonContent } from "@ionic/react";
import { useAppSelector } from "../app/hooks";
import {
  selectWorkerAnswerableQuestionnaires,
  selectWorkerHealthReports,
} from "../features/worker/workerSlice";

import "./Vigilancia.scss";
import Questionnaire from "../components/Questionnaire";
import Report from "../components/Report";
import Advice from "../components/Advice";
import Aptitude from "../components/Aptitude";
import Toolbar from "../components/Toolbar";
import MedicalHistoryDocument from "../components/MedicalHistoryDocument";

import { useTranslation } from "react-i18next";

const Vigilancia: React.FC = () => {
  const answerableQuestionnaires = useAppSelector(
    selectWorkerAnswerableQuestionnaires,
  );
  const healthReports = useAppSelector(selectWorkerHealthReports);
  const [t] = useTranslation("global");

  return (
    <IonPage>
      <Toolbar title={t("Vigilancia de la Salud")} />
      <IonContent fullscreen>
        <div className="vigil">
          <div className="vigil__body">
            {/* should better be in the header as like a notif  */}
            {/*
            TODO add a notification to the side should also perhaps add a notification.
            */}
            {answerableQuestionnaires.length > 0 && (
              <div className="vigil__column questionnaires">
                <div className="reports__head">
                  <div className="reports__title">
                    {t("Tienes cuestionarios a rellenar!")}
                  </div>
                </div>
                <div className="reports__body">
                  {answerableQuestionnaires.map((value: any, index: any) => {
                    return <Questionnaire key={index} rep={value} />;
                    // return (
                    //   <>
                    //     {/* <h3>{value.report_type}</h3>
                    //     <p>{value.main_reason}</p> */}
                    //     {/* <ul>
                    //       {value.questionnaires.map(
                    //         (questionnaire: any, index: any) => (
                    //           <li key={index}>
                    //             <a
                    //               href={questionnaire.url}
                    //               target="_blank"
                    //               rel="noopener noreferrer"
                    //             >
                    //               {questionnaire.file_name} (
                    //               {questionnaire.file_type})
                    //             </a>
                    //           </li>
                    //         ),
                    //       )}
                    //     </ul> */}
                    //   </>
                    // );
                  })}
                </div>
              </div>
            )}
          </div>
          <div className="vigil__body">
            <div className="vigil__column reports">
              <div className="reports__head">
                <div className="reports__title">{t("Informes")}:</div>
              </div>
              <div className="reports__body">
                {healthReports.map((report: any, index: any) => {
                  if (report.file_type === "aptitude_periods") {
                    return <Aptitude key={index} rep={report} />;
                  } else if (report.file_type === "medical_consultations") {
                    return <Report key={index} rep={report} />;
                  } else if (report.file_type === "medical_history_documents") {
                    return <MedicalHistoryDocument key={index} rep={report} />;
                  }
                  return "";
                })}
              </div>
            </div>
            <div className="vigil__column advice">
              <div className="reports__head">
                <div className="reports__title">{t("Consejos")}:</div>
              </div>
              <div className="reports__body">
                {healthReports.map((report: any, index: any) => {
                  if (report.show_advices === "True") {
                    return <Advice key={index} adv={report} />;
                  }
                  return "";
                })}
              </div>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Vigilancia;
