import { IonIcon, IonPage, IonContent, IonHeader, IonToolbar, IonButtons, IonMenuButton } from "@ionic/react"
import { alertCircleOutline } from 'ionicons/icons';

import { useTranslation } from "react-i18next";
import './NotFound.scss'

const NotFound:any = ()=>{
    const [t] = useTranslation("global");
    
    return(
        <IonPage>
            <IonHeader>
                <IonToolbar>
                <IonButtons slot="start">
                    <IonMenuButton />
                </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <div className="not-found">
                    <IonIcon className="not-found__icon" ios={alertCircleOutline} md={alertCircleOutline}></IonIcon>
                    <p className="not-found__error">Error 404<span>{t('Página no encontrada')}</span></p>
                </div>
            </IonContent>
        </IonPage>
    )
}

export default NotFound