import axios from "axios"
import { setAuthToken, tokenConfig } from "../helpers/setAuthToken";
import { setExpiredSession } from "../helpers/expiredSession"
import { setLogged, setLoginFail, setWrongPasswordValidation, setAccountActive } from "../features/login/loginSlice";

export interface ILoginResponse {
    access: string;
    refresh: string;
    server_url: string;
};

export interface ILoginState {
  logged: boolean;
  loginFail: boolean;
  wrongNewPassword: boolean;
  passwordValidationMessages: string[];
  accountActive: boolean;
};

export const activateAccount = ({uuid, token, oldPassword, newPassword, serverUrl}: {uuid:string, token:string, oldPassword:string, newPassword:string, serverUrl:string}) => async (dispatch:any) => {
  try {

      // Form Data
      const form_data = new FormData();
      form_data.append('uuid', uuid);
      form_data.append('token', token);
      form_data.append('old_password', oldPassword);
      form_data.append('new_password', newPassword);


      console.log("post activate account")
      await axios.post<ILoginResponse>( 
          `${serverUrl}/api/activate_account/`,
          form_data,
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
          },
      );
      
      console.log("Activada con éxito")

      dispatch(setAccountActive())

    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error message: ', error);
        if (error.response?.data.code === 2) {
          dispatch(setWrongPasswordValidation(error.response?.data.response))
        } else if (error.response?.data.code === 1) {
          alert("La contraseña actual no coincide")
        } else {
          alert(error.response?.data.response)
        }
        // 👇️ error: AxiosError<any, any>
        return error.message;
      } else {
        console.log('unexpected error: ', error);
        return 'An unexpected error occurred';
      }
    }
}

export const getLoginInfo = (props:any) => async (dispatch:any) => {
    try {
        const { data } = await axios.post<ILoginResponse>( 
            `${props.serverUrl}/api/token/`,
          { username: props.username, password: props.password },
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
          },
        );

        localStorage.setItem("access", data['access']); 
        localStorage.setItem("refresh", data['refresh']);
        localStorage.setItem("server_url", props.serverUrl);
        localStorage.setItem("logged", "true");
        setAuthToken(data['access'])  // TODO: Determinar si es necesario o no

        dispatch(setLogged())

        window.location.href = '/'
    } catch (error) {
        if (axios.isAxiosError(error)) {
          localStorage.setItem("logged", "false");
          dispatch(setLoginFail())
          console.log('error message: ', error.message);
          // 👇️ error: AxiosError<any, any>
          return error.message;
        } else {
          console.log('unexpected error: ', error);
          return 'An unexpected error occurred';
        }
    }
}

export const tokenUpdate = () => async (dispatch:any) => {
  try {
      const serverUrl = localStorage.getItem("server_url")

      const { data } = await axios.post<ILoginResponse>( 
          `${serverUrl}/api/token/refresh/`,
        { refresh: localStorage.getItem("refresh") },
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        },
      );

      localStorage.setItem("access", data['access']); 
      localStorage.setItem("refresh", data['refresh']);
      setAuthToken(data['access'])  // TODO: Determinar si hace falta o no

    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error message: ', error.message);
        setExpiredSession();
        // 👇️ error: AxiosError<any, any>
        return error.message;
      } else {
        console.log('unexpected error: ', error);
        return 'An unexpected error occurred';
      }
    }
}

export const changePassword = ({oldPassword, newPassword}: {oldPassword:string, newPassword:string}) => async (dispatch:any) => {
  try {
      const serverUrl = localStorage.getItem("server_url")

      // Form Data
      const form_data = new FormData();
      form_data.append('old_password', oldPassword);
      form_data.append('new_password', newPassword);

      await axios.post<ILoginResponse>( 
        `${serverUrl}/api/change_password/`,
        form_data,
        tokenConfig(),
      );

      alert("Contraseña cambiada con éxito. Se le redirigirá hacia la pantalla de ingreso para que entre con su nueva contraseña.")
      window.location.href = '/'

    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error message: ', error);
        if (error.response?.data.code === 2) {
          dispatch(setWrongPasswordValidation(error.response?.data.response))
        } else if (error.response?.data.code === 1) {
          alert("La contraseña actual no coincide")
        }
        // 👇️ error: AxiosError<any, any>
        return error.message;
      } else {
        console.log('unexpected error: ', error);
        return 'An unexpected error occurred';
      }
    }
}

export const deviceFCMToken = (token:any) => async (dispatch:any) => {
  const serverUrl = localStorage.getItem("server_url")

  try {
      // Form Data
      const form_data = new FormData();
      form_data.append('token', token);

      await axios.post<any>( 
          `${serverUrl}/api/device_fcm_token/`,
          form_data,
          tokenConfig()
      );

  } catch (error) {
      if (axios.isAxiosError(error)) {
          console.log('error message: ', error.message);
          return error.message;
      } else {
          console.log('unexpected error: ', error);
          return 'An unexpected error occurred';
      }
  }
}