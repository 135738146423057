import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { IAppointments } from '../../actions/appointmentManagement';


const initialState: IAppointments = {
  appointments: []
};

export const appointmentSlice = createSlice({
  name: 'appointment',
  initialState,
  reducers: {
    acceptAppointmentState: (state, action) => {
      state.appointments = state.appointments.map((appointment) => {
        if (appointment.id === action.payload) {
          appointment.status = "confirmed"
        }
        return appointment
      })
    },
    rejectAppointmentState: (state, action) => {
      state.appointments = state.appointments.map((appointment) => {
        if (appointment.id === action.payload) {
          appointment.status = "refused"
        }
        return appointment
      })
    },
    initialAppointments: (state, action) => {
        state.appointments = action.payload
    }
  },
});

export const { acceptAppointmentState, rejectAppointmentState, initialAppointments } = appointmentSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectAppointments = (state: RootState) => (state.appointment.appointments);

export default appointmentSlice.reducer;
