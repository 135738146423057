import { Capacitor } from "@capacitor/core"
import { FileOpener } from "@ionic-native/file-opener"
import { Filesystem, Directory } from '@capacitor/filesystem';

export const open = async (filePath) => {
  // Cargar ruta del archivo que descargamos antes

  if (!filePath) {
    throw new Error("No se ha descargado el archivo")
  }
  // Determinar que plataforma se esta usando
  const platform = Capacitor.getPlatform()
  if (platform === "web") {
    throw new Error("Operación no permitida en versión Web")
  }
  // Se debe especificar que tipo de archivo es
  const mimeType = "application/pdf"
  // Abrir archivo
  Filesystem.getUri({
    directory: Directory.Documents,
    path: filePath
  }).then((getUriResult) => {
    const path = getUriResult.uri;
    FileOpener.open(path, mimeType)
    .then(() => console.log('File is opened'))
    .catch(error => console.error('Error openening file', error));
  }, (error) => {
    console.error(error);
  });
}