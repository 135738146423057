import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { Worker } from "../../actions/workerInfo";

const initialState: Worker = {
  name: "",
  encrypted_personal_data: {
    email: "",
    birthday: "",
    name: "",
    first_surname: "",
    second_surname: "",
    mobile_phone: "",
    work_phone: "",
    phone: "",
    image: "",
  },
  full_address: {
    bis: 0,
    block: "",
    door: "",
    flat: "",
    locality: "",
    number: "",
    postal_code: "",
    province: "",
    stair: "",
    street: "",
    street_type: "",
    town: "",
  },
  active_periods: [],
  health_appointments: [],
  health_reports: [],
  answerable_questionnaires: [],
  worker_profile_picture: "",
};

export const workerSlice = createSlice({
  name: "worker",
  initialState,
  reducers: {
    setWorkerInfo: (state, action) => {
      state.name = action.payload.name;
      state.encrypted_personal_data = action.payload.encrypted_personal_data;
      state.full_address = action.payload.full_address;
      state.active_periods = action.payload.active_periods;
      state.health_appointments = action.payload.health_appointments;
      state.health_reports = action.payload.health_reports;
      state.answerable_questionnaires =
        action.payload.answerable_questionnaires;
      state.worker_profile_picture = action.payload.worker_profile_picture;
    },
    setWorkerProfilePicture: (state, action) => {
      state.encrypted_personal_data.image = action.payload;
    },
  },
});

export const { setWorkerInfo, setWorkerProfilePicture } = workerSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectWorker = (state: RootState) => state.worker;
export const selectWorkerInfo = (state: RootState) =>
  state.worker.encrypted_personal_data;
export const selectWorkerProfilePicture = (state: RootState) =>
  state.worker.worker_profile_picture;
export const selectWorkerFullAddress = (state: RootState) =>
  state.worker.full_address;
export const selectWorkerName = (state: RootState) => state.worker.name;
export const selectWorkerHealthAppointments = (state: RootState) =>
  state.worker.health_appointments;
export const selectWorkerHealthReports = (state: RootState) =>
  state.worker.health_reports;
export const selectWorkerAnswerableQuestionnaires = (state: RootState) =>
  state.worker.answerable_questionnaires;

export default workerSlice.reducer;
