import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { ILoginState } from '../../actions/loginInfo';


const initialState: ILoginState = {
  logged: false,
  loginFail: false,
  wrongNewPassword: false,
  passwordValidationMessages: [],
  accountActive: false,
};

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setLogged: (state) => {
      state.logged = true;
    },
    unsetLogged: (state) => {
      state.logged = false;
    },
    setLoginFail: (state) => {
      state.loginFail = true;
    },
    unsetLoginFail: (state) => {
      state.loginFail = false;
    },
    setWrongPasswordValidation: (state, action) => {
      state.wrongNewPassword = true;
      state.passwordValidationMessages = action.payload
    },
    unsetWrongPasswordValidation: (state) => {
      state.wrongNewPassword = false;
    },
    setAccountActive: (state) => {
      state.accountActive = true;
    },
    unsetAccountActive: (state) => {
      state.accountActive = false;
    },
  },
});

export const { setLogged, unsetLogged, setLoginFail, unsetLoginFail, setWrongPasswordValidation, unsetWrongPasswordValidation, setAccountActive, unsetAccountActive } = loginSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectLogged = (state: RootState) => (state.login.logged);
export const selectLoginFail = (state: RootState) => (state.login.loginFail);
export const selectWrongNewPassword = (state: RootState) => (state.login.wrongNewPassword);
export const selectPasswordValidationMessages = (state: RootState) => (state.login.passwordValidationMessages);
export const selectAccountActive = (state: RootState) => (state.login.accountActive);

export default loginSlice.reducer;
