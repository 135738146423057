import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { IHistoricalVariables } from '../../actions/historicalVariables';

const initialState: IHistoricalVariables = {
  historicalVariables: []
};

export const historicalVariablesSlice = createSlice({
  name: 'historicalVariables',
  initialState,
  reducers: {
    setHistoricalVariables: (state, action) => {
      state.historicalVariables = action.payload
    },
  },
});

export const { setHistoricalVariables } = historicalVariablesSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectHistoricalVariables = (state: RootState) => (state.historicalVariables.historicalVariables);

export default historicalVariablesSlice.reducer;
