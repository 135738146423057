import {LocalNotifications} from '@capacitor/local-notifications'

import { random } from 'lodash';

class Notifications {
  public async schedule(reportName: string) {
    try {
      // Request/ check permissions
      if ((await LocalNotifications.requestPermissions()).display !== 'granted') return;

    //   // Clear old notifications in prep for refresh (OPTIONAL)
    //   const pending = await LocalNotifications.getPending();
    //   if (pending.notifications.length > 0)
    //     await LocalNotifications.cancel(pending);

      await LocalNotifications.schedule({
        notifications: [
        {
            title: 'Archivo descargado',
            body: `Por favor, haga clic sobre esta notificación para abrir el documento ${reportName}`,
            id: random(0, 1000),
            extra: {
              filepath: reportName
            },
            actionTypeId: 'CHAT_MSG',
          }]
      });
    } catch (error) {
      console.error(error);
    }
  }
}

export default new Notifications()