import { useState } from 'react';
import { IonPage, IonContent, IonPopover, IonIcon } from '@ionic/react'
import { calendarClearOutline, timeOutline, locationOutline } from 'ionicons/icons';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import { useAppSelector } from "../app/hooks";
import { selectAppointments } from "../features/appointment/appointmentSlice";
import moment, {locale} from 'moment'
import { useTranslation } from 'react-i18next';

import 'moment/locale/es'; 
import 'moment/locale/ca'; 
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './Calendar.css'
import Toolbar from '../components/Toolbar';

const localizer = momentLocalizer(moment)

const CalendarTest = (props:any) => {

  const [t, i18n] = useTranslation("global");

  const healthAppointments = useAppSelector(selectAppointments);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [popoverInfo, setPopoverInfo] = useState({
    title: "",
    start: new Date(),
    status: "",
    medic: "",
    medical_center: "",
    type: ""
  })

  const StatusColor:any = {
    programmed : {
      text : getComputedStyle(document.documentElement).getPropertyValue('--bs-primary-500'),
      bkg  : getComputedStyle(document.documentElement).getPropertyValue('--bs-primary-t-025')
    },
    confirmed  : {
      text : getComputedStyle(document.documentElement).getPropertyValue('--color-green-500'),
      bkg  : getComputedStyle(document.documentElement).getPropertyValue('--color-green-t-025')
    },
    refused    : {
      text : getComputedStyle(document.documentElement).getPropertyValue('--bs-secondary-500'),
      bkg  : getComputedStyle(document.documentElement).getPropertyValue('--bs-secondary-t-025')
    },
  }

  const StatusTranslation:any = {
    programmed: `${t('Pendiente')}`,
    confirmed: `${t('Confirmada')}`,
    refused: `${t('Rechazada')}`
  }

  const eventList = healthAppointments.map((appointment, key) => {
    const start_date = new Date(`${appointment.date.split('T')[0]} ${appointment.date.split('T')[1]}`)
    const ed = new Date(`${appointment.date.split('T')[0]} ${appointment.date.split('T')[1]}`)
    const end_date = new Date(`${appointment.date.split('T')[0]} ${ed.getHours() + 1}:${ed.getMinutes()}:${ed.getSeconds()}`)
    return({
      id: key,
      title: appointment.main_reason,
      start: start_date,
      end: end_date,
      status: appointment.status,
      medic: appointment.medic,
      medical_center: appointment.medical_center,
      type: appointment.appointment_type
    })
  })

  const selectEvent = (eventInfo:any) => {

    setPopoverOpen(true);
    setPopoverInfo({
      title: eventInfo.title,
      start: eventInfo.start,
      status: eventInfo.status,
      medic: eventInfo.medic,
      medical_center: eventInfo.medical_center,
      type: eventInfo.type
    })
  }

  const eventStyleGetter = (event: any, start: Date, end: Date, isSelected: boolean) => {
    var backgroundColor = StatusColor[event.status]['bkg'];
    var color = StatusColor[event.status]['text'];

    var style = {
        backgroundColor,
        borderRadius: '.5rem',
        opacity: 1,
        color,
        border: '5px',
        display: 'block'
    };
    return {
        style: style,
        className: event.status
    };
  }

  const [language, setLanguage] = useState(i18n.language);

  return (
    <IonPage>
      <Toolbar title={t('Agenda')} language={language} setLanguage={setLanguage} />
      <IonContent fullscreen>
        <Calendar
          localizer={localizer}
          culture={locale(`${language}`)}
          events={eventList}
          startAccessor="start"
          endAccessor="end"
          style={{ height: "90vh" }}
          onSelectEvent={(eventInfo)=>selectEvent(eventInfo)}
          onShowMore={(e)=>{console.log(e)}}
          eventPropGetter={eventStyleGetter}
          messages={{
            next: `${t('sig')}`,
            previous: `${t('ant')}`,
            today: `${t('Hoy')}`,
            month: `${t('Mes')}`,
            week: `${t('Semana')}`,
            day: `${t('Día')}`
          }}
          tooltipAccessor={(e) => 
            `
            ${e.title}\n${('Estado')}: ${StatusTranslation[e.status]}\n${('Fecha')}: ${e.start.getDate()-1}/${e.start.getMonth()+1}/${e.start.getFullYear()} ${e.start.getHours()}:${e.start.getMinutes()}\n${t('Solicitado por')}: ${e.medic}
            `
        }
        />
      </IonContent>
      <IonPopover className='calendar' trigger="top-center" size="auto" side="top" alignment="center" isOpen={popoverOpen} onDidDismiss={() => setPopoverOpen(false)}>
        <IonContent className="ion-padding">
          <p className={'popover__status ' + popoverInfo.status }>{StatusTranslation[popoverInfo.status]}</p>
          <div className="notif__title">
            <p className='notif__title-text'>
                <span>{popoverInfo.type}</span>
                {popoverInfo.title}
            </p>
          </div>
          <p className='notif__date'>
            <span>
              <IonIcon className='menu_notif__icon' ios={calendarClearOutline} md={calendarClearOutline} />
              {`${popoverInfo.start.toLocaleString().split(', ')[0]}`}
            </span>
            <span>
              <IonIcon className='menu_notif__icon' ios={timeOutline} md={timeOutline} />
              {`${popoverInfo.start.toLocaleString().split(', ')[1].substring(0, popoverInfo.start.toLocaleString().split(', ')[1].length - 3)}`}
            </span>
          </p>
          <div className="notif__body">
            <div className="notif__props">
              <div className="notif__prop">
                  <IonIcon ios={locationOutline} md={locationOutline}></IonIcon>
                  {`${popoverInfo.medical_center}`}
              </div>
              <div className="notif__prop">
              <p className='notif__p'>
                <span className='notif__span'>{t('Solicitado por')}:</span>
                {popoverInfo.medic}
              </p>
              </div>
            </div>
          </div>
        </IonContent>
      </IonPopover>
    </IonPage>
)
 
}

export default CalendarTest;
