import React, { useContext } from 'react';
import { IonContent, IonHeader, IonPage, IonAlert } from '@ionic/react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import ChangePasswordActivate from '../components/ChangePasswordActivate';
import { selectAccountActive } from '../features/login/loginSlice';
import { unsetAccountActive } from '../features/login/loginSlice';  
import { ServerUrlContext } from '../contexts/ServerUrlContext';

interface ActivaAccountParams {
    uuid: string;
    token: string;
  }

const ActivateAccount: React.FC = () => {
    const dispatch = useAppDispatch();
    const activateAccountState = useAppSelector(selectAccountActive); 

    const { uuid, token } = useParams<ActivaAccountParams>();
    const serverUrl = useContext(ServerUrlContext);



    return (
        <IonPage>
        <IonHeader>
        </IonHeader>
        <IonContent>
            <ChangePasswordActivate 
                uuid={uuid}
                token={token}
                serverUrl={serverUrl}
            />
            <IonAlert
                isOpen={activateAccountState}
                onDidDismiss={() => {
                    dispatch(unsetAccountActive())
                    window.location.href = "/"
                }}
                message="Se ha activado la cuenta con éxito. Se le redigirá a la pantalla de ingreso para que entre con su nueva contraseña."
                buttons={['De acuerdo']}
            />
            
        </IonContent>
        </IonPage>
    );
};

export default ActivateAccount;

