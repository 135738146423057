import { IonIcon, IonPage, IonContent, IonItem, useIonModal,} from "@ionic/react"

import './Home.scss'
import { calendarClearOutline, notificationsOutline, clipboardOutline, heartOutline, exitOutline, shieldOutline } from 'ionicons/icons';

import { setAuthToken   } from '../helpers/setAuthToken';
import { useAppSelector } from '../app/hooks';
import { selectWorker   } from '../features/worker/workerSlice';

import { useTranslation } from 'react-i18next';
import Toolbar from "../components/Toolbar";
import { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import ModalCreateNotification from "../components/modals/ModalCreateNotification";

interface AppPage {
    url     : string;
    iosIcon : string;
    mdIcon  : string;
    title   : string;
}

const Home:any = ()=>{

    const [t] = useTranslation("global");

    const appPages: AppPage[] = [
        {
            title   : t('Notificaciones'),
            url     : '/notificaciones',
            iosIcon : notificationsOutline,
            mdIcon  : notificationsOutline
        },
        {
            title   : t('Agenda'),
            url     : '/agenda',
            iosIcon : calendarClearOutline,
            mdIcon  : calendarClearOutline
        },
        {
            title   : t('Vigilancia de la Salud'),
            url     : '/vigilancia',
            iosIcon : clipboardOutline,
            mdIcon  : clipboardOutline
        },
        {
            title   : t('Mi Salud'),
            url     : '/mi-salud',
            iosIcon : heartOutline,
            mdIcon  : heartOutline
        },
        {
            title   : t('Información de Prevención'),
            url     : '/informacion-de-prevencion',
            iosIcon : shieldOutline,
            mdIcon  : shieldOutline
        },
        // {
        //     title: t('Contacto'),
        //     url: '/page/Contacto',
        //     iosIcon: paperPlaneOutline,
        //     mdIcon: paperPlaneOutline
        // },
    ];

    const [presentCreateNotification, dismissCreateNotification] = useIonModal(ModalCreateNotification, {
        onDismiss: (data: string, role: string) => dismissCreateNotification(data, role),
    }, );

    function openCreateNotificationModal() {
        presentCreateNotification({
            onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {},
        },);
    } 

    const homeButtons : any = [
        {
            text    : t('Crear una Notificación'),
            action  : openCreateNotificationModal,
        }
    ]

    const removeToken = ()=>{
        localStorage.removeItem("access");
        localStorage.removeItem("refresh");
        localStorage.setItem("logged", "false");
        setAuthToken("")
    }

    const worker = useAppSelector(selectWorker);

    return(
        <IonPage>
            <Toolbar title='' />
            <IonContent fullscreen>
                <div className="home">
                    <p className="home__greeting">{t('¡Hola')} { worker.name }!<span>{t('Estás accediendo a tu portal de prevención')}</span></p>
                    <ul className="home__ul">
                        { appPages.map((appPage, index) => {
                        return (
                            <li className="home__li" key={index}>
                                <IonItem routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                                    <div className="home__a">
                                        <span><IonIcon className="home__icon" ios={appPage.iosIcon} md={appPage.mdIcon}></IonIcon></span>
                                        <h3 className="home__title">{appPage.title}</h3>
                                    </div>
                                </IonItem>
                            </li>
                        )})}
                        <li className="home__li exit">
                            <IonItem routerLink="/login" routerDirection="none" lines="none" detail={false} onClick={()=>removeToken()}>
                                <div className="home__a">
                                    <span><IonIcon className="home__icon" ios={exitOutline} md={exitOutline}></IonIcon></span>
                                    <h3 className="home__title">{t('Salir')}</h3>
                                </div>
                            </IonItem>
                        </li>
                    </ul>
                    <ul className="home__buttons">
                        {
                            homeButtons.map(( button : any, i : number ) => {
                                return (
                                    <li className="home__button-li" key={i}>
                                        <button onClick={ button.action } className="home__button button-base solid">{ button.text }</button>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </IonContent>
        </IonPage>
    )
}

export default Home