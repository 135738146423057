import { IonPage, IonContent } from "@ionic/react";

import "./WebSign.scss";
import Toolbar from "../components/Toolbar";
import { t } from "i18next";
import { RouteComponentProps } from "react-router";
import { useEffect, useState } from "react";

interface ParamsDetails
  extends RouteComponentProps<{
    type: string;
    id: string;
  }> {}

function Viewer({ url }: { url: string }) {
  const [src, setSrc] = useState<string | null>(null);

  useEffect(() => {
    fetch(`${localStorage.getItem("server_url")}/${url}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
      method: "POST",
      body: '{"password":"bm4bL3Zz"}',
    })
      .then((response) => response.blob())
      .then((blob) => {
        const blobUrl = URL.createObjectURL(blob);
        setSrc(blobUrl);
      });
  }, [url]);

  return src ? (
    <embed src={src} type="application/pdf" width="100%" height="800px" />
  ) : null;
}

const PDFPreview: React.FC<ParamsDetails> = ({ match }) => {
  const urlPath = `/api/request_report/?report=${match.params.type}&report-id=${match.params.id}`;

  return (
    <IonPage>
      <Toolbar title={t("Document viewer")} />
      <IonContent fullscreen>
        <button>Download file</button>
        <div id="pdf">
          <Viewer url={urlPath} />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default PDFPreview;
